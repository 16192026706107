import React from "react"
import "normalize.css"
import "./src/styles/global.css"

import SearchProvider from "./src/providers/SearchProvider"

export const wrapRootElement = props => {
  const { element } = props

  return <SearchProvider>{element}</SearchProvider>
}
