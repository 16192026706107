// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dim-sum-menu-js": () => import("./../../../src/pages/dim_sum_menu.js" /* webpackChunkName: "component---src-pages-dim-sum-menu-js" */),
  "component---src-pages-drink-menu-js": () => import("./../../../src/pages/drink_menu.js" /* webpackChunkName: "component---src-pages-drink-menu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-special-js": () => import("./../../../src/pages/special.js" /* webpackChunkName: "component---src-pages-special-js" */)
}

