import React, { createContext, useState } from "react"

const defaultState = ["", () => {}]

export const SearchContext = createContext(defaultState)

const { Provider } = SearchContext

const SearchProvider = props => {
  const { children } = props

  const [state, setState] = useState("")

  return <Provider value={[state, setState]}>{children}</Provider>
}

SearchProvider.context = SearchContext

export default SearchProvider
